import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts/Layout'
import store from '@/store'
import { beforeEach } from '@/router/guards'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Layout,
  children: [{
    path: '',
    name: 'Home',
    component: () => import('@/views/UserManagement/Administrators.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'administrators',
    name: 'Administratorzy',
    component: () => import('@/views/UserManagement/Administrators.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'traders',
    name: 'Użytkownicy',
    component: () => import('@/views/UserManagement/Traders.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'offer-for-you',
    name: 'OfferYou',
    props: {
      type: 0
    },
    component: () => import('@/views/Article/Articles.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'offer-for-salesman',
    name: 'OfferTrader',
    props: {
      type: 1
    },
    component: () => import('@/views/Article/Articles.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'groups',
    name: 'Grupy',
    component: () => import('@/views/Groups/Groups.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'groups-details/:id',
    name: 'Detale Grupy',
    component: () => import('@/views/Groups/GroupDetails.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'article-statistics/:id',
    name: 'Statystki oferty',
    component: () => import('@/views/Article/ArticleStatistics.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-offer-for-you/:id?',
    name: 'offer-for-you',
    props: {
      type: 0
    },
    component: () => import('@/views/Article/AddOrEditArticle.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-offer-for-salesman/:id?',
    name: 'offer-for-salesman',
    props: {
      type: 1
    },
    component: () => import('@/views/Article/AddOrEditArticle.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'newspaper',
    name: 'Gazetki',
    component: () => import('@/views/Newspaper/Newspaper.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'product',
    name: 'Produkty i nowości',
    component: () => import('@/views/Product/Product.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'contest-and-quiz',
    name: 'Contest',
    component: () => import('@/views/Contest/Contest.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-contest/:id?',
    name: 'AddOrEditContest',
    props: {
      type: 2
    },
    component: () => import('@/views/Contest/AddOrEditContest.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-quiz/:id?',
    name: 'AddOrEditQuiz',
    props: {
      type: 3
    },
    component: () => import('@/views/Contest/AddOrEditQuiz.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'news',
    name: 'News',
    component: () => import('@/views/News/News.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-news/:id?',
    props: {
      type: 5
    },
    name: 'AddOrEditNews',
    component: () => import('@/views/News/AddOrEditNews.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'targets',
    name: 'Targets',
    component: () => import('@/views/Targets/Targets.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-targets/:id?',
    props: {
      type: 5
    },
    name: 'AddOrEditTargets',
    component: () => import('@/views/Targets/AddOrEditTargets.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'chat',
    name: 'Chat',
    component: () => import('@/views/Chat/Chat.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'tracking-url',
    name: 'TrackingUrl',
    component: () => import('@/views/Tracking/TrackingUrl.vue'),
    beforeEnter: guardRoute
  },
  {
    path: 'add-or-edit-product/:id?',
    name: 'AddOrEditProduct',
    props: {
      type: 4
    },
    component: () => import('@/views/Product/AddOrEditProduct.vue'),
    beforeEnter: guardRoute
  }
  ]
},
{
  path: '/',
  name: 'GlobalLayout',
  beforeEnter: guardRoute,
  component: () => import('@/layouts/GlobalLayout'),
  children: [{
    path: 'login',
    name: 'Login',
    component: () => import('@/views/Auth/Login.vue')
  },
  {
    path: 'reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/Auth/ResetPassword')
  },
  {
    path: 'reset-password-code',
    name: 'ResetPasswordCode',
    component: () => import('@/views/Auth/ResetPasswordCodeVerification')
  },
  {
    path: '/first-login',
    component: () => import('@/views/Auth/FirstLogin')
  },
  {
    path: '/forbidden',
    component: () => import('@/views/Errors/Forbidden')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@/views/Errors/Notfound')
  }]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function guardRoute (to, from, next) {
  if (store.getters.isLoggedIn) {
    // if (to.path == "/login" || to.path == "/register") {
    if (to.path === '/login') {
      next({
        name: 'Home'
      })
    }
    next()
  }

  if (!store.getters.isLoggedIn) {
    if (to.path === '/login' || to.path === '/first-login') {
      next()
    } else {
      // store.state.redirect = to.fullPath
      next({
        name: 'Login'
      })
    }
  }
  // if (!store.getters.isLoggedIn) {
  //   // if (to.path == "/login" || to.path == "/register") {
  //   if (to.path === '/login') {
  //     next()
  //   } else {
  //     store.state.redirect = to.fullPath
  //     next({
  //       name: 'Login'
  //     })
  //   }
  // }
  next()
}

router.beforeEach((to, from, next) => {
  return beforeEach(to, from, next)
})

export default router
