<template>
  <v-navigation-drawer
    id="default-drawer"
    color="primary"
    v-model="drawer"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
    dark
  >
    <div class="px-2">
      <v-list-item class="mb-0 justify-space-between pl-3">
        <v-list-item-content class="pl-2">
          <v-list-item-title class="text-h2">
            <v-img contain
            height="34"
            class="ma-auto"
            src="" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-3 mb-2" />

      <v-list expand nav v-bind="$attrs" v-on="$listeners">
        <template v-for="(item, i) in items">
          <v-list-item
            v-if="item.show"
            :key="i"
            :href="item.href"
            :rel="item.href ? 'nofollow' : undefined"
            :target="item.href ? '_blank' : undefined"
            :to="item.to"
            active-class="primary white--text"
            link
            class="py-1"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <v-list-item-icon
              v-if="!item.icon"
              class="text-caption text-uppercase justify-center ml-1 my-2 align-self-center"
            >
              {{ title }}
            </v-list-item-icon>

            <v-list-item-icon v-if="item.icon" class="my-2 align-self-center">
              <v-icon v-text="item.icon" />
            </v-list-item-icon>

            <v-list-item-content v-if="item.title">
              <v-list-item-title>
                {{item.title}}
                <span v-show="(unreadMessageCount > 0)" id="chat-unread-message-badge" v-if="item.title == 'Chat'">
                  ∗
              </span>

              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { adminRoleId } from '@/utils/helpers'
export default {
  name: 'SideBar',
  data () {
    return {
      unreadMessageCount: 0,
      drawer: true,
      mini: false,
      items: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: '/',
          show: false
        },
        {
          title: this.$t('users.administrators'),
          icon: 'mdi-account-star',
          to: '/administrators',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: this.$t('users.title'),
          icon: 'mdi-account-tie',
          to: '/traders',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: this.$t('groups.title'),
          icon: 'mdi-account-group',
          to: '/groups',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: 'Oferty dla Ciebie',
          icon: 'mdi-archive-check-outline',
          to: '/offer-for-you',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: 'Oferty sprzedawcy',
          icon: 'mdi-archive-check-outline',
          to: '/offer-for-salesman',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: this.$t('newspaper.title'),
          icon: 'mdi-newspaper-variant-outline',
          to: '/newspaper',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: this.$t('product.title'),
          icon: 'mdi-ice-cream',
          to: '/product',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: this.$t('contest.title'),
          icon: 'mdi-alpha-q-circle-outline',
          to: '/contest-and-quiz',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: 'Aktualności',
          icon: 'mdi-newspaper-variant-multiple ',
          to: '/news',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: this.$t('target.title'),
          icon: 'mdi-bullseye-arrow  ',
          to: '/targets',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: 'Chat',
          icon: 'mdi-chat-processing-outline',
          to: '/chat',
          show: this.$store.getters.userRoleId === adminRoleId
        },
        {
          title: 'Adres śledzenia',
          icon: 'mdi-truck-delivery-outline',
          to: '/tracking-url',
          show: this.$store.getters.userRoleId === adminRoleId
        }
      ]
    }
  },
  mounted () {
    this.checkUnreadMessagesAsync()
    setInterval(() => this.checkUnreadMessagesAsync(), 60000)
  },
  methods: {
    async checkUnreadMessagesAsync () {
      const count = (await this.$http.get('/Frontend/CountAdminUnreadMessages')).data
      this.unreadMessageCount = count > 0 ? 1 : 0
    }
  },
  watch: {
    '$route.name': {
      handler: function (val) {
        if (val === 'Chat') {
          this.unreadMessageCount = 0
        }
      }
    }
  }
}
</script>
<style>
  #chat-unread-message-badge{
    background-color: #0696c7;
    height: 18px;
    min-width: 18px;
    border-radius: 78%;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    display: inline-flex;
  }
</style>
